import InfoBanner from "@/components/Ui/Pages/InfoBanner/InfoBanner";
import useSlider from "@/hooks/common/useSlider";
import { baseURL } from "@/services/api";
import {
  IBestSellerDataGeneric,
  IBestSellers,
  IBrands,
  IBrandsGeneric,
  ICategory,
  ICategoryGeneric,
  ISlider,
} from "global-types/global.types";
import { GetStaticProps } from "next";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import VideoModal from "@/components/Ui/Tools/VideoModal/VideoModal";
import { t } from "@/helper/helper";
import { NextSeo } from "next-seo";
import { SITE_NAME, SITE_URL } from "@/helper/constant";
import { useRouter } from "next/router";
import axios from "axios";
import Image from "next/image";
import styled from "styled-components";
import OrderSuccessModal from "@/components/Ui/Tools/OrderSuccessModal/OrderSuccessModal";
import { useParams } from "next/navigation";
import ErrorModal from "@/components/Ui/Pages/ErrorModal/ErrorModal";


export interface IHomeMeta {
  data: {
    meta: {
      description: string | null;
      keywords: string;
      title: string;
    };
    og_img: string | null;
  };
}

export interface IHomeSettings {
  data: {
    video: string;
    banner1: string;
    banner2: string;
    banner3: string;
    banner_url1: string;
    banner_url2: string;
    banner_url3: string;
    video_cover: string;
  };
}

interface Props {
  slider: ISlider;
  brands: IBrands<IBrandsGeneric[]>;
  locale: string;
  bestSellers: IBestSellers<IBestSellerDataGeneric<IBrandsGeneric>[]>;
  newProducts: IBestSellers<IBestSellerDataGeneric<IBrandsGeneric>[]>;
  homeMeta: IHomeMeta;
  homeSettings: IHomeSettings;
  headerData: ICategory<ICategoryGeneric[]>;
}

export default function HomePage(props: Props) {
  const { asPath } = useRouter();

  const { data, isLoading } = useSlider({
    initialData: props.slider,
  });

  // state
  const [showSlider, setShowSlider] = useState<number>(0);

  useEffect(() => {
    const clear = setInterval(() => {
      if (showSlider < props.slider?.data.length - 1) {
        setShowSlider((prev) => prev + 1);
      } else {
        setShowSlider(0);
      }
    }, 4000);

    return () => clearInterval(clear);
  }, [showSlider]);

  const checkChildren = (
    item: ICategoryGeneric["children"],
    slug: string,
    _slug: string
  ) => {
    if (item?.length === 0) {
      return `/${props.locale}/product-list/${slug}`;
    } else {
      return `/${props.locale}/categories/${slug}`;
    }
  };

  const [video, setVideo] = useState<boolean>(false);

  function Dots(count: number): React.ReactNode {
    const allItems: Array<React.ReactNode> = [];

    for (let index = 0; index < count; index++) {
      allItems.push(
        <ButtonDot
          onClick={() => setShowSlider(index)}
          className={index === showSlider ? "activeDot" : ""}
        />
      );
    }
    return allItems.map((item) => item);
  }

  const { query } = useRouter()

  const [isShowSuccessModal, setIsShowSuccess] = useState(false);


  const [isErrorModal, setIsErrorModal] = useState(false)

  useEffect(() => {
    if (query.payment === "successfull") {
      setIsShowSuccess(true)
    } else if (query.payment === "declined") {
      setIsErrorModal(true)
    }

  }, [query.payment, query])


  return (
    <>
      <NextSeo
        title={`${props.homeMeta.data?.meta?.title
          ? props.homeMeta.data.meta.title + " | "
          : ""
          } ${SITE_NAME}`}
        // @ts-ignore
        description={
          props.homeMeta.data?.meta?.description
            ? props.homeMeta.data.meta.description.substring(0, 160)
            : ""
        }
        openGraph={{
          url: SITE_URL + "/" + props.locale + asPath,
          title: props.homeMeta.data?.meta?.title
            ? props.homeMeta.data?.meta?.title
            : "",
          // @ts-ignore
          description: props.homeMeta.data?.meta?.description
            ? props.homeMeta.data?.meta?.description.substring(0, 300)
            : "",
          images: [
            {
              // @ts-ignore
              url: props.homeMeta.data.og_img,
            },
          ],
          siteName: SITE_NAME,
        }}
      />

      {
        isErrorModal && <ErrorModal
          setClose={setIsErrorModal}
          content={t("paymentError", props.locale)}
        />
      }

      {
        isShowSuccessModal && <OrderSuccessModal
          locale={props.locale}
          slug={""}
          toggleModal={setIsShowSuccess}
        />
      }
      {video ? (
        <VideoModal setVideo={setVideo} src={props.homeSettings.data.video} />
      ) : (
        <></>
      )}

      <div className="single-column-responsive-layout style-alt">
        <div
          className="page-home hide-on-search"
          data-test="home-content-desktop"
        >
          <div className="section-promo">
            <aside className="promo-sidebar-banners">
              <div className="widget contact-us hide-mobile">
                <h2 className="title">
                  <Link href={`${props.locale === "az" ? "" : "/"}contact`}>
                    {t("contactUs", props.locale)}{" "}
                  </Link>
                </h2>
                <div className="contact-item">
                  <div className="phone">
                    <span className="phone-icon" />
                    <div>
                      <span className="phone-number ">+994 (51) 407 63 45</span>
                      {/* <span className="phone-number ">+994 (51) 407 63 45</span> */}
                    </div>
                  </div>
                </div>
                <div className="contact-item">
                  <a className="email" href="mailto:info@promusic.az">
                    info@promusic.az
                  </a>
                </div>
                <div className="contact-item">
                  <Link href={`${props.locale === "az" ? "" : "/"}/locations`}>
                    {t("ourLocation", props.locale)}
                  </Link>
                </div>
                <div className="video-our-story">
                  <a
                    className="fnc-bx-vid video"
                    onClick={() => setVideo(true)}
                  >
                    <Image
                      src={props.homeSettings.data.video_cover}
                      width={140}
                      height={96}
                      alt="Video"
                    />
                    <span>{t("watchStory", props.locale)}</span>
                  </a>
                </div>
              </div>
              <section id="top-brands" className="sidebar-box">
                <h2 className="title">
                  <Link href={`${props.locale === "az" ? "" : "/"}/brand`}>
                    {t("topBrandsPage", props.locale)}
                  </Link>
                </h2>
                <ul className="brands">
                  {props.brands?.data.map((item, index) =>
                    index < 8 ? (
                      <li
                        className="brand"
                        data-xplr="man-0-144"
                        key={index.toString() + item.name}
                      >
                        <Link
                          className="brand-link"
                          href={`/${props.locale}/brand/${item.slug}`}
                        >
                          <Image
                            width={400}
                            height={400}
                            className="brand-logo"
                            src={`${item.logo}`}
                            alt={item.name}
                          />
                        </Link>
                      </li>
                    ) : (
                      <React.Fragment key={index.toString() + item.name} />
                    )
                  )}
                </ul>
                <div className="btn-holder">
                  <Link className="button" href={`/${props.locale}/brand`}>
                    {t("showAllBrands", props.locale)}{" "}
                  </Link>
                </div>
              </section>
            </aside>
            <div className="promo-main">
              <div className="merchandising-banner">
                {!isLoading && (
                  <section
                    id="merchandising-banner-gallery"
                    className="gallery"
                  >
                    <div className="gallery-image">
                      <button
                        className="gallery-left"
                        onClick={() => {
                          if (showSlider > 0) setShowSlider((prev) => prev - 1);
                          else setShowSlider(data!.data.length - 1);
                        }}
                      >
                        <svg
                          style={{
                            transition: "0.2s",
                            color: "#fff",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                          />
                        </svg>
                      </button>
                      <button
                        className="gallery-right"
                        onClick={() => {
                          if (showSlider < data!.data.length - 1)
                            setShowSlider((prev) => prev + 1);
                          else setShowSlider(0);
                        }}
                      >
                        <svg
                          style={{
                            transition: "0.2s",
                            color: "#fff",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </button>
                      {data?.data.map((item, index) => (
                        <a
                          key={index.toString() + item.title}
                          id="gallery-image-list"
                          className="gallery-image-list"
                          href={item.url}
                        >
                          <div
                            id="gallery-image-list-item_2"
                            className={`gallery-image-list-item ${showSlider === index ? "active" : ""
                              }`}
                            style={{
                              backgroundImage: `url(${item.image})`,
                            }}
                          >
                            <div className="gallery-des">
                              <span className="title">{item.title}</span>
                              <span>{item.description}</span>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </section>
                )}
              </div>
              <ButtonsGallery>
                {!isLoading && data && Dots(data.data.length)}
              </ButtonsGallery>
              <InfoBanner locale={props.locale} />
              <div className="widget--category-boxes">
                <div className="g4m-grid-category-boxes category-boxes">
                  {props.headerData?.data?.map((item, index) => (
                    <div
                      key={index.toString() + item.name}
                      className="g4m-grid-item category-box"
                      style={{
                        backgroundImage: `url(${item.banner})`,
                      }}
                    >
                      <div className="text-block">
                        <h2 className="category-box-title">
                          <Link
                            href={`/${props.locale}/categories/` + item.slug}
                          >
                            {item.name}
                          </Link>
                        </h2>
                        <ul className="category-detailed hide-mobile">
                          {item.children.map((_item, index) => (
                            <li key={index.toString() + _item.name}>
                              <Link
                                href={checkChildren(
                                  _item.children,
                                  _item.slug,
                                  item.slug
                                )}
                              >
                                {_item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>{" "}
            </div>
            <aside className="promo-sidebar-brands">
              <div className="banner-clearance banner-1">
                <section
                  className="sidebar-box hide-mobile web-banner test"
                  data-xplr="bnr-10543"
                >
                  <Link
                    href={props.homeSettings.data.banner_url1}
                    className="big-deals"
                  >
                    <Image
                      width={160}
                      height={1025}
                      id="ws-bnr-231"
                      alt={props.homeSettings.data.banner1}
                      className="g4m-lazy-load g4m-lazy-load-onview g4m-lazy-load-onview--complete"
                      src={props.homeSettings.data.banner1}
                    />
                  </Link>
                </section>{" "}
              </div>
              <div className="banner-clearance banner-2">
                <section
                  className="sidebar-box hide-mobile web-banner test"
                  data-xplr="bnr-10543"
                >
                  <Link
                    href={props.homeSettings.data.banner_url2}
                    className="big-deals"
                  >
                    <Image
                      width={160}
                      height={1025}
                      id="ws-bnr-231"
                      alt={props.homeSettings.data.banner2}
                      className="g4m-lazy-load g4m-lazy-load-onview g4m-lazy-load-onview--complete"
                      src={props.homeSettings.data.banner2}
                    />
                  </Link>
                </section>{" "}
              </div>
              <div className="banner-clearance banner-3">
                <section
                  className="sidebar-box hide-mobile web-banner test"
                  data-xplr="bnr-10543"
                >
                  <Link
                    href={props.homeSettings.data.banner_url3}
                    className="big-deals"
                  >
                    <Image
                      width={160}
                      height={1025}
                      id="ws-bnr-231"
                      alt={props.homeSettings.data.banner3}
                      className="g4m-lazy-load g4m-lazy-load-onview g4m-lazy-load-onview--complete"
                      src={props.homeSettings.data.banner3}
                    />
                  </Link>
                </section>{" "}
              </div>
            </aside>
          </div>
          <div className="section-featured">
            <h1 className="featured-products-title">
              {t("bestSeller", props.locale)}
            </h1>
            <div className="g4m-grid g4m-grid-product-listing">
              {props.bestSellers?.data.map((item, index) =>
                index < 8 ? (
                  <Link
                    key={index.toString() + item.title}
                    className="g4m-grid-item product-card-legacy"
                    href={`/${props.locale}/` + item.slug}
                  >
                    <h3 data-xplr="inv-4-141952" className="title">
                      {item.title}
                    </h3>
                    <div className="image-wrapper">
                      {!!item.discount_type ? (
                        <div className="product-discount-rec active">
                          - {item.discount}
                          {item.discount_type === "1" ? (
                            <svg
                              className="manatIconList"
                              width={33}
                              height={27}
                              viewBox="0 0 33 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15 26L15.8511 1.03704L18.6596 0L19 24L15 26Z"
                                fill="#FF4C4C"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 26.7126L3.31441 26.8563C3.37837 10.7044 12.0742 5.37873 16.3559 5.22923C27.9253 5.59751 29.2515 20.3484 29.6856 26.8563L33 27C32.7507 11.4088 24.1817 2.85531 17.1485 3.00185C5.15613 3.42337 0.588832 15.6295 0 26.7126Z"
                                fill="#FF4C4C"
                              />
                            </svg>
                          ) : (
                            <span style={{ marginLeft: 5 }}>
                              <svg
                                width={10}
                                height={10}
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  width="2.99993"
                                  height="2.99993"
                                  rx="1.49996"
                                  fill="white"
                                />
                                <rect
                                  x="6.99884"
                                  y={7}
                                  width="2.99993"
                                  height="2.99993"
                                  rx="1.49996"
                                  fill="white"
                                />
                                <rect
                                  x="8.49884"
                                  width="1.99995"
                                  height="11.9997"
                                  rx="0.999976"
                                  transform="rotate(45 8.49884 0)"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      <Image
                        className="g4m-lazy-load g4m-lazy-load-onview image g4m-lazy-load-onview--complete"
                        src={`${item.image}`}
                        width={300}
                        height={300}
                        alt={item.title}
                      />
                    </div>
                    <div
                      className="description  can-order "
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    />

                    <div className="availability">
                      <div className="price  can-order ">
                        {!!item.discount_type ? (
                          <p className="old-price newDiscount">
                            {item.price}{" "}
                            <svg
                              width={13}
                              height={13}
                              viewBox="0 0 33 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15 26L15.8511 1.03704L18.6596 0L19 24L15 26Z"
                                fill="#000"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 26.7126L3.31441 26.8563C3.37837 10.7044 12.0742 5.37873 16.3559 5.22923C27.9253 5.59751 29.2515 20.3484 29.6856 26.8563L33 27C32.7507 11.4088 24.1817 2.85531 17.1485 3.00185C5.15613 3.42337 0.588832 15.6295 0 26.7126Z"
                                fill="#000"
                              />
                            </svg>
                          </p>
                        ) : (
                          <></>
                        )}

                        {!!item.discount_type ? (
                          item.discount_type === "1" ? (
                            <>
                              {item.price - item.discount}
                              <svg
                                width={20}
                                height={20}
                                viewBox="0 0 33 27"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M15 26L15.8511 1.03704L18.6596 0L19 24L15 26Z"
                                  fill="#000"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0 26.7126L3.31441 26.8563C3.37837 10.7044 12.0742 5.37873 16.3559 5.22923C27.9253 5.59751 29.2515 20.3484 29.6856 26.8563L33 27C32.7507 11.4088 24.1817 2.85531 17.1485 3.00185C5.15613 3.42337 0.588832 15.6295 0 26.7126Z"
                                  fill="#000"
                                />
                              </svg>
                            </>
                          ) : (
                            item.price -
                            ((item.price * item.discount) / 100 || 0)
                          )
                        ) : (
                          <>
                            {item.price}{" "}
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 33 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15 26L15.8511 1.03704L18.6596 0L19 24L15 26Z"
                                fill="#000"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 26.7126L3.31441 26.8563C3.37837 10.7044 12.0742 5.37873 16.3559 5.22923C27.9253 5.59751 29.2515 20.3484 29.6856 26.8563L33 27C32.7507 11.4088 24.1817 2.85531 17.1485 3.00185C5.15613 3.42337 0.588832 15.6295 0 26.7126Z"
                                fill="#000"
                              />
                            </svg>
                          </>
                        )}
                      </div>
                      {/* <div className="stock-message  can-order ">
                        Available for order{" "}
                      </div> */}
                    </div>
                  </Link>
                ) : (
                  <></>
                )
              )}
            </div>
          </div>
          <div className="section-featured">
            <h2 className="featured-products-title">
              {t("newProducts", props.locale)}
            </h2>
            <div className="g4m-grid g4m-grid-product-listing">
              {props.newProducts?.data.map((item, index) =>
                index < 8 ? (
                  <Link
                    key={index.toString() + item.title}
                    className="g4m-grid-item product-card-legacy"
                    href={`/${props.locale}/` + item.slug}
                  >
                    <h3 data-xplr="inv-4-141952" className="title">
                      {item.title}
                    </h3>
                    <div className="image-wrapper">
                      {!!item.discount_type ? (
                        <div className="product-discount-rec active">
                          -{item.discount}
                          {item.discount_type === "1" ? (
                            <svg
                              className="manatIconList"
                              width={33}
                              height={27}
                              viewBox="0 0 33 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15 26L15.8511 1.03704L18.6596 0L19 24L15 26Z"
                                fill="#FF4C4C"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 26.7126L3.31441 26.8563C3.37837 10.7044 12.0742 5.37873 16.3559 5.22923C27.9253 5.59751 29.2515 20.3484 29.6856 26.8563L33 27C32.7507 11.4088 24.1817 2.85531 17.1485 3.00185C5.15613 3.42337 0.588832 15.6295 0 26.7126Z"
                                fill="#FF4C4C"
                              />
                            </svg>
                          ) : (
                            <span style={{ marginLeft: 5 }}>
                              <svg
                                width={10}
                                height={10}
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  width="2.99993"
                                  height="2.99993"
                                  rx="1.49996"
                                  fill="white"
                                />
                                <rect
                                  x="6.99884"
                                  y={7}
                                  width="2.99993"
                                  height="2.99993"
                                  rx="1.49996"
                                  fill="white"
                                />
                                <rect
                                  x="8.49884"
                                  width="1.99995"
                                  height="11.9997"
                                  rx="0.999976"
                                  transform="rotate(45 8.49884 0)"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      <Image
                        className="g4m-lazy-load g4m-lazy-load-onview image g4m-lazy-load-onview--complete"
                        src={`${item.image}`}
                        width={300}
                        height={300}
                        alt={item.title}
                      />
                    </div>
                    <div
                      className="description  can-order "
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    />

                    <div className="availability">
                      <div className="price  can-order ">
                        {!!item.discount_type ? (
                          <p className="old-price newDiscount">
                            {item.price}{" "}
                            <svg
                              width={13}
                              height={13}
                              viewBox="0 0 33 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15 26L15.8511 1.03704L18.6596 0L19 24L15 26Z"
                                fill="#000"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 26.7126L3.31441 26.8563C3.37837 10.7044 12.0742 5.37873 16.3559 5.22923C27.9253 5.59751 29.2515 20.3484 29.6856 26.8563L33 27C32.7507 11.4088 24.1817 2.85531 17.1485 3.00185C5.15613 3.42337 0.588832 15.6295 0 26.7126Z"
                                fill="#000"
                              />
                            </svg>
                          </p>
                        ) : (
                          <></>
                        )}

                        {!!item.discount_type ? (
                          item.discount_type === "1" ? (
                            <>
                              {item.price - item.discount}
                              <svg
                                width={20}
                                height={20}
                                viewBox="0 0 33 27"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M15 26L15.8511 1.03704L18.6596 0L19 24L15 26Z"
                                  fill="#000"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0 26.7126L3.31441 26.8563C3.37837 10.7044 12.0742 5.37873 16.3559 5.22923C27.9253 5.59751 29.2515 20.3484 29.6856 26.8563L33 27C32.7507 11.4088 24.1817 2.85531 17.1485 3.00185C5.15613 3.42337 0.588832 15.6295 0 26.7126Z"
                                  fill="#000"
                                />
                              </svg>
                            </>
                          ) : (
                            item.price -
                            ((item.price * item.discount) / 100 || 0)
                          )
                        ) : (
                          <>
                            {item.price}{" "}
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 33 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15 26L15.8511 1.03704L18.6596 0L19 24L15 26Z"
                                fill="#000"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 26.7126L3.31441 26.8563C3.37837 10.7044 12.0742 5.37873 16.3559 5.22923C27.9253 5.59751 29.2515 20.3484 29.6856 26.8563L33 27C32.7507 11.4088 24.1817 2.85531 17.1485 3.00185C5.15613 3.42337 0.588832 15.6295 0 26.7126Z"
                                fill="#000"
                              />
                            </svg>
                          </>
                        )}
                      </div>
                      {/* <div className="stock-message  can-order ">
                        Available for order{" "}
                      </div> */}
                    </div>
                  </Link>
                ) : (
                  <></>
                )
              )}
            </div>
          </div>
        </div>
        <aside className="aside footer-about-g4m">
          <section className="block block1">
            <h2>{t("aboutHomeTitle", props.locale)}</h2>
            <p>{t("aboutHomeDescription", props.locale)}</p>
          </section>
        </aside>
      </div>
    </>
  );
}

const ButtonsGallery = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const ButtonDot = styled.button`
  background-color: transparent;
  padding: 5px;
  outline: 0;
  border: 2px solid #222;
  border-radius: 100%;

  &.activeDot {
    position: relative;
    display: flex;

    &:after {
      position: absolute;
      content: "";
      width: 5px;
      height: 5px;
      background-color: #222;
      left: 53%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
    }
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const getStaticProps: GetStaticProps = async (ctx) => {
  const { locale } = ctx;

  const headers: Record<string, string> = {
    "x-localization": locale ? locale : "az",
  };

  const homeMeta = await axios.get(baseURL + "/home/meta", {
    headers,
  });

  const slider = await axios.get(baseURL + "/home/slider", {
    headers,
  });
  const brands = await axios.get(baseURL + "/home/brand", {
    headers,
  });
  const bestSellers = await axios.get(baseURL + "/home/bestsellers", {
    headers,
  });
  const newProducts = await axios.get(baseURL + "/home/new", {
    headers,
  });

  const homeSettingsData = await axios.get(baseURL + "/home/setting", {
    headers,
  });

  return {
    props: {
      slider: slider.data,
      brands: brands.data,
      bestSellers: bestSellers.data,
      locale,
      newProducts: newProducts.data,
      homeMeta: homeMeta.data,
      homeSettings: homeSettingsData.data,
    },
    revalidate: 60,
  };
};
