import RenderSvg from "@/components/Svg/RenderSvg";
import { t } from "@/helper/helper";
import Link from "next/link";
import styled from "styled-components";

interface Props {
  locale: string;
  slug: string;
  toggleModal: (val: boolean) => void;
}

export default function OrderSuccessModal({
  locale,
  toggleModal,
  slug,
}: Props) {
  return (
    <Wrapper>
      <div className="success-modal-overlay">
        <div className="modal-x">
          <div className="content" style={{ display: "block" }}>
            <div className="top">
              <div className="success">
                <RenderSvg title="success" />
              </div>
              <button className="close-btn" onClick={() => toggleModal(false)}>
                <img
                  src={require("@/assets/img/x.png").default.src}
                  alt="close"
                />
              </button>
            </div>
            <div className="title-text">{t("orderSuccessfully", locale)}</div>
            <div className="text">{t("willCall", locale)}</div>
            <Link href={`/${locale}/${slug}`}>
              {t("startShopping", locale)}
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .success-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    z-index: 999999999999999;

    .modal-x {
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      width: 53.5rem;
      height: auto;
      padding: 3.6rem;

      .top {
        display: flex;
        margin-bottom: 20px;

        .success {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .ellipse {
          width: 260px;
          height: 105px;
          margin-left: auto;
          margin-right: auto;
          background: #d0d4de;
          opacity: 0.25;
          border-radius: 52.5px;
        }

        .close-btn {
          width: 28px;
          height: 28px;
          left: 1164px;
          top: 286px;
          border-radius: 3px;
          background: transparent !important;
        }
      }

      .title-text {
        font-family: "Nunito";
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        color: #1b1c1f;
        text-align: center;
        margin-bottom: 24px;
      }

      p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #444444;
        padding: 0 0 36px;
        text-align: center;
      }

      a {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 20px;
        height: 60px;
        color: #ffffff;
        background: #1b1c1f;
        border-radius: 6px;
        width: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-decoration: unset;
      }

      .text {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;
        color: #444444;
        text-align: center;
        margin-bottom: 24px;
      }

      .orders-btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 23.6rem;
        height: 6rem;
        background: #ff4c4c;
        border-radius: 6px;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 24px;
        color: #ffffff;
        margin-top: 2.4rem;
      }
    }

    @media screen and (max-width: 767.9px) {
      .modal-x {
        width: 90vw !important;

        .succes {
          svg {
            width: 100px;
            height: 100px;
          }
        }

        .title-text {
          font-size: 20px !important;
          margin-bottom: 16px !important;
        }

        a {
          font-size: 16px !important;
          height: 50px !important;
        }
      }
    }
  }
`;
